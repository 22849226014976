<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import repas from "./repas";
import { required} from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
// import sideButtons from "@/components/side-buttons";
import SideButtons from '../../../../../../components/side-buttons.vue';
import cancelation from '../../../../../../components/cancelation.vue';
import {getDropDownElementsByTypeApi} from "@/api/common";
import {printSingleDocumentUrl} from "@/helpers/functions";
const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;
const after = (value) => value >= now;
const before = (value,vm) => value < vm.selectedContract.end_date;
const obg = (value) => value != 0;


export default {
    page: {
        title: "Ajout ODS",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {/*Switches,*/ repas,Multiselect, SideButtons,cancelation},
    import : {printSingleDocumentUrl},
    props:{
      ODS:{
        type:Array,
        default:undefined
      },
      total_month:{
        type:Number,
        default:0
      },
      disableButtons:{
        type:Boolean,
        default:false
      }
    },
    data() {
      return {
      estimation_types: [],
      selectedEstimation: [],
      getEmit:false,
      info:false,
      add:false,
      save:false,
      saveToPending:false,
      confirm:false,
      validate:false,
      edit:false,
      print:false,
      destroy:false,
      estimated:false,
      cancel:false,
      cancelModal:false,
      pob:0,
      title: "Ordre de Service",
      selectedBdv:0,
      contracts:[],
      visitors:[],
      selectedContract:{catering_amnt:0},
      ODS_info: {
        type_ODS:'catering',
        Bdv:0,
        ID_CTT:0,
        prestataire:0,
        date_ODS:now,
        date_exec_ODS:now,
        period:'',
        total_amount:0,
        status:'draft',
        comments:'',
        is_extra:false
      },
      Repas:{},
      send:false,
      disabled:false,
      resetForm:false,
      RepasService:[],
      filtredRepas:[],
      typeRepas:[],
      have:false,
      bases:[],
      prests:[],
      selectedPrest:'',
      Cancelation:'',
      periods:[
        {label:"Periode Fête", value:"F"},
        {label:"Periode Normal", value:"N"},
        {label:"Periode Ramadhan", value:"R"},
      ],
      submitted:false,
      reject:false,
      reset:false,

    };
  },
  validations: {
        ODS_info:{
            Bdv:{
              required,
              obg
            },
            ID_CTT:{
              required,
              obg
            },
            prestataire:{
              required,
              obg
            },
            date_ODS:{
              required,
              after
            },
            date_exec_ODS:{
              required,
              after,
              // before
            },
            period:{
              required
            },
        } 
  },
  watch:{
    ODS:{
      handler(val){
        if(val.length <= 0) {this.resetData();this.resetForm=true; this.have = false}
        else {
          if(this.add || this.edit){
          Swal.fire({
            title: 'Êtes-vous sûr ?',
            text: "Des elements sont en cours de saisie, êtes-vous de quitter ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui'
          }).then((result) => {
            if (result.isConfirmed) {
              var _this = this;
              this.resetForm=true;
              setTimeout(function(){ 
                _this.ODS_info= {
                  type_ODS:'catering',
                  Bdv:0,
                  ID_CTT:0,
                  prestataire:0,
                  date_ODS:now,
                  date_exec_ODS:now,
                  period:'',
                  total_amount:0,
                  status:'draft',
                  comments:'',
                  is_extra:false
                }
                _this.RepasService = []
                _this.filtredRepas = []
                _this.Repas        = []
                _this.ODS_info.id                  =  val[0].id;
                _this.ODS_info.ref                 =  val[0].ref;
                _this.ODS_info.Bdv                 =  val[0].Bdv;
                _this.ODS_info.ID_CTT              =  val[0].contract.id;
                _this.ODS_info.prestataire         =  val[0].service_provider.id;
                _this.ODS_info.date_ODS            =  val[0].date_ODS;
                _this.ODS_info.date_exec_ODS       =  val[0].date_exec_ODS;
                _this.ODS_info.period              =  val[0].period;
                _this.ODS_info.status              =  val[0].status;
                _this.ODS_info.done                =  val[0].done;
                _this.ODS_info.canceled_at         =  val[0].canceled_at;
                _this.ODS_info.canceled_reason     =  val[0].canceled_reason;
                _this.ODS_info.canceled_by         =  val[0].canceled_by;
                _this.ODS_info.cancel_by           =  val[0].cancel_by;
                _this.ODS_info.lifebase            =  val[0].lifebase
                _this.ODS_info.comments            =  val[0].comment;
                _this.ODS_info.is_extra            =  val[0].is_extra == 1 ? true : false;
                _this.ODS_info.total_amount        =  val[0].totalAmnt.plain
                _this.ODS_info.items               =  val[0].items
                _this.RepasService                 =  Object.keys(val[0].items);
                _this.filtredRepas                 =  val[0].items;
                _this.RepasService.forEach(element => {
                  _this.Repas[element]             =  undefined;
                })
                _this.cancelModal = false;
                _this.cancel = false;
                _this.selectedContract             =  val[0].contract;
                _this.selectedBdv                  =  val[0].lifebase;
                _this.bases.push(_this.selectedBdv);
                _this.selectedPrest                =  val[0].service_provider;
                _this.resetForm                    =  false;
                if(val[0].status == 'approved') _this.have = false
                else _this.have = true;
              }, 2000,val)
              

            }
          })
        }
        else {
          this.ODS_info= {
                  type_ODS:'catering',
                  Bdv:0,
                  ID_CTT:0,
                  prestataire:0,
                  date_ODS:now,
                  date_exec_ODS:now,
                  period:'',
                  total_amount:0,
                  status:'draft',
                  comments:'',
                  is_extra:false
                }
          this.RepasService = []
          this.filtredRepas = []
          this.Repas        = []
          // console.log(val.length);
          this.ODS_info.id                  =  val[0].id;
          this.ODS_info.uuid                =  val[0].uuid;
          this.ODS_info.ref                 =  val[0].ref;
          this.ODS_info.Bdv                 =  val[0].Bdv;
          this.ODS_info.ID_CTT              =  val[0].contract.id;
          this.ODS_info.prestataire         =  val[0].service_provider.id;
          this.ODS_info.date_ODS            =  val[0].date_ODS;
          this.ODS_info.date_exec_ODS       =  val[0].date_exec_ODS;
          this.ODS_info.period              =  val[0].period;
          this.ODS_info.status              =  val[0].status;
          this.ODS_info.done                =  val[0].done;
          this.ODS_info.comments            =  val[0].comment;
          this.ODS_info.is_extra            =  val[0].is_extra == 1 ? true : false;
          this.ODS_info.lifebase            =  val[0].lifebase
          this.ODS_info.canceled_reason     =  val[0].canceled_reason;
          this.ODS_info.canceled_by         =  val[0].canceled_by;
          this.ODS_info.canceled_at         =  val[0].canceled_at;
          this.ODS_info.cancel_by           =  val[0].cancel_by;
          this.ODS_info.total_amount        =  val[0].totalAmnt.plain
          this.ODS_info.items               =  val[0].items
          this.RepasService                 =  Object.keys(val[0].items);
          this.filtredRepas                 =  val[0].items;
          this.RepasService.forEach(element => {
            this.Repas[element]             =  undefined;
          })
          this.cancelModal = false;
          this.cancel = false;
          
          
          this.selectedContract             =  val[0].contract;
          this.selectedBdv                  =  val[0].lifebase;
          this.bases.push(this.selectedBdv);
          this.selectedPrest                =  val[0].service_provider;
          this.resetForm                    =  false;
          if(val[0].status == 'approved') this.have = false
          else this.have = true;
          this.getcurrentPOB();
          this.getVisitorCompanies()
        }
          
      }
    }
    },
    add:{
      handler(val){
        this.resetForm=false;
        if(val && this.$can('create_rest_ods')){
            // console.log("clearForm");
            this.disabled = true;
            this.resetForm = false;
            this.resetData();
        }
        else if(val && !this.$can('create_rest_ods')) {Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error');this.resetForm=true;}
      }
    },
    getEmit:{
      handler(val){
        if(val){
          // console.log("get emit total");
          this.getTotalAmountODS()
          
        }
      }
    },
    saveToPending:{
      handler(val){
        if(val && this.canAny(["edit_rest_ods","create_rest_ods"])){
          Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de le mettre en Attente de Confirmation ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              // console.log("Save To Pending");
              this.ODS_info.status = "pending_confirmation";
              if(this.ODS_info.id) this.onUpdate();
              else this.onComplete();
            }
          })
        }
        else if(val && !this.canAny(["edit_rest_ods","create_rest_ods"])) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    edit:{
      handler(val){
        if( ( val && this.$can('edit_rest_ods') ) || ( val && this.ODS_info.status==('confirmed' || 'approved') && this.$can('override_rest_ods') ) ){
          this.disabled = true;
          this.resetForm = false;
        }
        else if(val && !this.$can('edit_rest_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    cancel:{
      handler(val){
        if( ( val && this.$can('override_rest_ods') && this.ODS_info.done != 1)){
          this.cancelODS()
        }
        else if(val && !this.$can('override_rest_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    resetForm:{
        handler(val){
          // console.log(val);
            if(val) {
                // console.log('reset');
                this.resetData();
                this.disabled=false;
            }
        }
    },
    confirm:{
      handler(val){
        if(val && this.$can('confirm_rest_ods')){
          Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de confirmer cet ODS ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              this.onValidate("Confirm");
            }
          })
          
        }
        else if(val && !this.$can('confirm_rest_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    reject:{
      handler(val){
        if(val && this.$can('confirm_rest_ods')){
          Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de rejeter cet ODS ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              this.onValidate("Reject");
            }
          })
        }
        else if(val && !this.$can('confirm_rest_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    validate:{
      handler(val){
        if(val && this.$can('validate_rest_ods')){
          Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de Valider cet ODS ? ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              this.onValidate("Validate");
            }
          })
          
        }
        else if(val && !this.$can('validate_rest_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    destroy:{
      handler(val){
        if((val && this.$can('delete_rest_ods')) || (val && this.ODS_info.status==('confirmed' || 'approved') && this.$can('override_rest_ods') ) )this.onDelete();
        else if(val && !this.$can('delete_rest_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
    print:{
      handler(val){
        if(val && this.$can('validate_rest_ods')) this.printSingleDocument('single_rest_so', this.ODS_info.uuid);
        else if(val && this.$can('validate_rest_ods')) Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')
      }
    },
  },
  mounted(){
    this.ODS_info.date_ODS = this.TodayDate();
    this.ODS_info.date_exec_ODS = this.TodayDate();
    // this.getLifeBases();
    this.getlistPeriod();
    this.getlistContracts();
    
  },

  methods:{
    getEstimationTypes(){
      this.$http.get('/ods/estimationTypes')
      .then(res => {
        // console.log(res.data);
        this.estimation_types = res.data
      })
      .catch(error =>(console.log(error)))
    },
    getlistContracts(){
      this.$http.post('/ods/listContractsBytype',{type:"catering"})
      .then(response => {
        this.contracts = response.data;
        let contracts_list = [];
        response.data.forEach(element => {
          contracts_list.push(element);
        });
        // contracts_list.unshift({id:0,designation:'Tout',ref:'Tout'})
        this.$emit('getListContracts', contracts_list);
      })
      .catch(error=> console.log(error))
    },
    getLifeBases(){
      this.$http.post('/base/lifebases/list')
      .then(response => {
          this.bases = response.data.original.list;
      })
      .catch(error => console.log(error))
    },
    onComplete(){
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
          // console.log("ERROR");
          this.save = false;
      }
      else {
          // console.log("Valid");
          if(!this.ODS_info.id){this.getTotalAmountODS()}
          else if(this.ODS_info.id && this.ODS_info.status==("draft" || "pending_confirmation")){this.getTotalAmountODS()}
          
          // console.log(this.ODS_info);
          this.$http.post('/ods/OdsRestauration/store',{
              ods:this.ODS_info, items:this.Repas
          })
          .then(response => {
              this.send = true;
              this.$emit('odsvalues',response.data);
              Swal.fire("Félicitations!", "ODS "+response.data.ref, "success");
              this.resetData();
              this.$v.$reset();
              this.add = false;
              this.send=false;
              this.disabled=false;
              this.resetForm=true;
              this.saveToPending=false;
          })
          .catch(error => {console.log(error); this.send = false})
      }
    },
    onUpdate(){
      // this.submitted = true
      // this.$v.$touch()
      // if (this.$v.$invalid) {
      //     console.log("ERROR");
      //     this.saveToPending=false;
      //     this.edit=false;
      // }
      // else {
          if(this.ODS_info.id && this.ODS_info.status==("draft" || "pending_confirmation")){this.getTotalAmountODS()}
          // console.log("Valid");
          this.$http.post('/ods/OdsRestauration/update',{
              ods:this.ODS_info, items:this.Repas
          })
          .then(response => {
              this.send = true;
              this.$emit('odsvalues',response.data);
              if(this.saveToPending) {Swal.fire("Félicitations!", "ODS "+this.ODS_info.ref+" a été enregistrer et mis en attente de confirmation", "success");this.saveToPending=false;}
              else Swal.fire("Félicitations!", "ODS "+this.ODS_info.ref+" à jour", "success");
              this.resetData();
              this.send=false;
              this.saveToPending=false;
              this.edit=false;
              this.disabled=false;
              this.resetForm=true;
              
          })
          .catch(error => console.log(error))
      // }
    },
    onValidate(data){
          this.$http.post('/ods/OdsRestauration/validate',{
              ods:this.ODS_info,status:data
          })
          .then(response => {
              this.send = true;
              this.$emit('odsvalues',response.data);
              if(data == "Confirm") Swal.fire("Félicitations!", this.ODS_info.ref+" Confirmé", "success");
              if(data == "Validate") Swal.fire("Félicitations!", this.ODS_info.ref+" Approuvé", "success");
              if(data == "Reject") Swal.fire("Félicitations!", this.ODS_info.ref+" Rejeté", "success");
              
              this.resetData();
              this.send=false;
              this.validate=false;
              this.confirm=false;
          })
          .catch(error => console.log(error))
    },

    onDelete(){
      if((this.ODS_info.status == ('draft' || 'rejected'))|| ( this.ODS_info.status==('pending_confirmation' ||'confirmed' || 'approved') && this.$can('override_rest_ods') ) ){
         var _this = this;
          Swal.fire({
            title: "Êtes-vous sûr de supprimer l'ODS: " + _this.ODS_info.ref + "?",
            text: "Cette Action est irréversible",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor:"#f46a6a",
            cancelButtonColor:  "#34c38f",
            confirmButtonText: 'Oui!',
            cancelButtonText: 'Annuler!',
          }).then((result) => {
            if (result.value) {
              this.$http
                .post("ods/OdsRestauration/delete",{ods:this.ODS_info})
                .then((res) => {
                  // console.log(res);
                  var status = res.data.status;
                  switch (status) {
                    case 200:
                      _this.$toast.success(res.data.msg);
                      this.$emit('odsvalues',res.data);
                      // this.$emit('refresh',true);
                      this.resetData();
                      this.disabled = false;
                      break;

                    case 500:
                      this.$toast.warning(res.data.msg);
                      break;
                  }
                })
                .catch((error) => {
                  _this.$toast.error(error.message);
                })
                .finally(() => {});
            }
          });
          this.destroy=false;
      }
      else this.$toast.warning("Vous ne pouvez pas supprimé un ODS déja confirmé ou approuvé");
    },
    getServiceRepas(){
      this.$http.post('/ods/OdsRestauration/listRepas',{type:this.ODS_info.type_ODS,contract_id:this.ODS_info.ID_CTT,Bdv:this.ODS_info.Bdv,period:this.ODS_info.period,estimation:this.selectedEstimation.id})
      .then(response => {
        this.Repas={};
        let List = Object.keys(response.data);
          if(List.length > 0){
            List.forEach(element => {
              this.Repas[element] = undefined
            })
            this.RepasService = List;
            this.filtredRepas = response.data;
            // this.emptyPrest = false;
          }
          else {
            Swal.fire(
              'Vide',
              'Aucune Prestation Trouvé',
              'warning'
            )
            this.Repas = {};
            this.RepasService = [];
            this.filtredRepas = [];
          }
        
        
      })
      .catch(error => console.log(error))
    },
    getlistPeriod(){
      getDropDownElementsByTypeApi("meal_period")
      .then(res => {
        // console.log(res);
        this.periods = res.data.original.list
      })
      .catch(error => (console.log(error)))
    },
    getTypeRepas(){
      this.$http.post('base/operations3D/restauration/typeRepas/list')
      .then(response => {
        let List = response.data;
        
        List.forEach(element => {
            element.value = false;
          });
        this.typeRepas = List;
      })
      .catch(error => console.log(error))
    },
    TodayDate(){
        var td, dt = new Date;
        td = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

        return td;
    },
    getContractID(contract){
      this.$emit('currentContract',contract);
      // console.log(contract.camps_services.map(element => {return element.camp}))
      this.selectedBdv=[];
      this.ODS_info.ID_CTT = contract.id;
      this.ODS_info.status ='draft';
      this.bases           = contract.camps_services.map(element => {return element.camp});
      this.prests.push(contract.supplier);
      this.selectedPrest   = contract.supplier;
      if(this.bases.length == 1) {
        this.selectedBdv = this.bases[0];
        this.getLifeBaseID(this.bases[0]);
      }
      this.ODS_info.prestataire = contract.supplier.id;
      this.ODS_info.period ="";
      this.RepasService = [];
      this.estimation_types = [];
      this.filtredRepas = [];
      this.Repas={};
      
      // console.log(this.bases);
    },
    getLifeBaseID(lifebase){
      this.ODS_info.Bdv      = lifebase.id;
      this.ODS_info.period ="";
      this.getcurrentPOB();
      this.RepasService = [];
      this.filtredRepas = [];
      this.getEstimationTypes();
      this.Repas={};
    },
    getcurrentPOB(campID){
      this.$http.post('ops/booking/getCurrentPob',{campId:campID})
      .then( response => {
        this.pob = response.data.pob
      })
      .catch(error => {
        console.log(error);
        this.pob = 0;
      })
    },
    resetData(){
        // this.disabled=false;
        this.selectedContract = [],
        this.selectedBdv = [],
        this.selectedPrest = [],
        this.selectedEstimation = [],
        this.bases = [],
        this.prests = [],
        this.send = true,
        this.ODS_info = {
          type_ODS     : 'catering',
          Bdv          : 0,
          ID_CTT       : '',
          prestataire  : 0,
          date_ODS     : now,
          date_exec_ODS: now,
          period       : '',
          comments     : ''
        };
        this.RepasService = [],
        this.filtredRepas = [],
        this.cancelModal = false;
        this.have=false
        this.estimated=false
        this.Repas={};
    },
    getTotalAmountODS(){
        var total=0;
        // console.log(this.Repas);
        let keys = Object.keys(this.Repas);
        // console.log(this.Repas);
        keys.forEach(element => {
            if(this.Repas[element]){
              let list = Object.keys(this.Repas[element]);
              list.forEach(x=> {
                total += parseInt(this.Repas[element][x].total_amount);
              })
            } 
        })
        // console.log(total);
        if(total){
          this.ODS_info.total_amount = total;
          this.$emit('totalCurrentOds',total);
          this.getEmit = false;
          return total;
        }
        else {
          total=0
          this.ODS_info.total_amount = 0;
          this.$emit('totalCurrentOds',total);
          this.getEmit = false;
          return total;
        }
        
    },
    filterRepasPeriod(){
      // console.log(this.ODS_info.period);
      this.getServiceRepas();
      this.getVisitorCompanies();
    },
    printSingleDocument(doc, uid){
      printSingleDocumentUrl(doc, uid);
      this.print =false;
    },
    getVisitorCompanies(){
      this.$http.post('ops/requests/visitors_support/getCountVisitorsRequest',{date:this.ODS_info.date_exec_ODS})
      .then(response => {
        // console.log(response);
        this.visitors = response.data;
      })
      .catch(error => (console.log(error)))
    },
    cancelODS(){
      var _this = this;
          Swal.fire({
            title: "Êtes-vous sûr d'annuler l'ODS: " + _this.ODS_info.ref + "?",
            text: "Cette Action est irréversible",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor:"#f46a6a",
            cancelButtonColor:  "#34c38f",
            confirmButtonText: 'Oui!',
            cancelButtonText: 'Annuler!',
          }).then((result) => {
            if (result.value) {
              this.$http
                .post('/ods/OdsRestauration/cancel',{id:_this.ODS_info.id,motif:_this.Cancelation.motif})
                .then((res) => {
                  // console.log(res);
                  var status = res.data.status;
                  switch (status) {
                    case 200:
                      _this.$toast.success(res.data.msg);
                      this.$emit('odsvalues',res.data);
                      this.Cancelation="";
                      this.resetData();
                      this.disabled = false;
                      this.cancelModal = true;
                      break;

                    case 500:
                      this.$toast.warning(res.data.msg);
                      break;
                  }
                })
                .catch((error) => {
                  _this.$toast.error(error.message);
                })
                .finally(() => {});
            }
            else {
              this.Cancelation="";
            }
          });
    }
  }
    
}
</script>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    
    <div class="row">
      <div class="col-12">
        <SideButtons   :reset="resetForm" :canEdit="!have"
          :select="have" :save="send" :ODS="true" :service_order="ODS_info" @info ="info = $event" @add="add = $event" @print="print=$event"
          @saveToPending="saveToPending = $event" @edit=" edit = $event" @SaveEdit="SaveEdit = $event" @confirm=" confirm = $event" 
          @reject="reject = $event" @validate=" validate = $event" @resetEmit="resetForm = $event" @delete=" destroy = $event" />
          
        <!-- <b-tabs content-class="mt-0">
          <b-tab title="Informations Générales" active> -->
              <div class="p-3">
                <cancelation v-if="ODS_info.id" :closeModal="cancelModal" :ods="ODS_info" @cancel_reason="Cancelation = $event" @cancel="cancel=$event"></cancelation>
                <div>
                  <!-- <h4 class="card-title">New </h4> -->
                      <!-- <b-alert show variant="info"> <i class="mdi mdi-information-outline"></i> Les champs obligatoires sont marqués avec (*) </b-alert> -->
                      <div class="row">
                            
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="formrow-password-input">N° Contrat</label>
                                <multiselect v-model="selectedContract" :select-label="''" :disabled="!disabled" :class="{'bg-light':!disabled,'disabledMS':!disabled}" :value="ODS_info.ID_CTT" @select="getContractID" track-by="id" label="ref" placeholder="Select one" :options="contracts" :searchable="false" :allow-empty="false">
                                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.ref }}</strong></template>
                                </multiselect>
                                <div v-if="submitted && $v.ODS_info.ID_CTT.$error">
                                    <span class="text-danger" v-if="!$v.ODS_info.ID_CTT.obg">Le Contrat est Obligatoire</span>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="formrow-inputCity"
                                  >Nom de la Base *</label
                                >
                                <multiselect v-model="selectedBdv" :class="{'bg-light':!disabled,'disabledMS':!disabled}" :select-label="''" @select="getLifeBaseID" :disabled="(bases.length == (1 || 0)) || !disabled" :value="ODS_info.Bdv" track-by="id" label="name" placeholder="Select one" :options="bases" :searchable="false" :allow-empty="false">
                                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                                </multiselect>
                                <div v-if="submitted && $v.ODS_info.Bdv.$error">
                                    <span class="text-danger" v-if="!$v.ODS_info.Bdv.obg">La base de vie est Obligatoire</span>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-4">
                                <label for="">Prestataire *</label>
                                <multiselect v-model="selectedPrest" :class="{'bg-light':!disabled,'disabledMS':!disabled}" :select-label="''" :disabled="true" :value="ODS_info.prestataire" track-by="id" label="prestname" placeholder="Select one" :options="prests" :searchable="false" :allow-empty="false">
                                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.prestname }}</strong></template>
                                </multiselect>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-3">
                                <label for="">Date ODS</label>
                                <input type="date" class="form-control" v-model="ODS_info.date_ODS" :disabled="!disabled" readonly :class="{'is-invalid': $v.ODS_info.date_ODS.$error}" >
                                <div v-if="submitted && $v.ODS_info.date_ODS.$error">
                                    <span class="text-danger" v-if="!$v.ODS_info.date_ODS.after">la date doit être superieure ou égal à aujourd'hui</span>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <label for="">Date execution</label>
                                <input type="date" class="form-control" v-model="ODS_info.date_exec_ODS" :disabled="!disabled" :class="{'is-invalid': $v.ODS_info.date_exec_ODS.$error || ODS_info.date_exec_ODS > selectedContract.end_date}">
                                <div v-if="submitted && $v.ODS_info.date_exec_ODS.$error">
                                    <span class="text-danger" v-if="!$v.ODS_info.date_exec_ODS.after">la date doit être superieure ou égal à aujourd'hui</span>
                                    <!-- <span class="text-danger" v-if="!$v.ODS_info.date_exec_ODS.before">la date doit être inferieure à la date fin de contrat</span> -->
                                </div>
                                <div v-if="ODS_info.date_exec_ODS > selectedContract.end_date">
                                    <!-- <span class="text-danger" v-if="!$v.ODS_info.date_exec_ODS.after">la date doit être superieure ou égal à aujourd'hui</span> -->
                                    <span class="text-danger">la date doit être inferieure à la date fin de contrat</span>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <label for="">Type Estimation *</label>
                                <multiselect v-model="selectedEstimation" @select="ODS_info.period = ''" :class="{'bg-light':!disabled,'disabledMS':!disabled}" :select-label="''" :disabled="!disabled"  track-by="id" label="name" placeholder="" :options="estimation_types" :searchable="false" :allow-empty="false">
                                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                                </multiselect>
                            </div>
                            <div class="col-lg-3">
                                <label for="">Type de Periode *</label>
                                <select class="form-control" :disabled="!disabled" v-model="ODS_info.period" @change="filterRepasPeriod" :class="{'is-invalid': $v.ODS_info.period.$error}">
                                    <option v-for="period in periods" :key="period.id" :value="period.id">{{period.designation}}</option>
                                </select>
                                <div v-if="submitted && $v.ODS_info.period.$error">
                                    <span class="text-danger" v-if="!$v.ODS_info.period.required">le type de periode est obligatoire</span>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Commentaires</label>
                                <textarea
                                  v-model="ODS_info.comments"
                                  class="form-control"
                                  cols="30"
                                  rows="1" 
                                  required
                                  :disabled="!disabled"
                                ></textarea>
                              </div>
                            </div>
                        </div>

                      <!-- end row -->
                </div>
                <!-- end card-body -->
          </div>
          <div class="p-3">
            <div class="row">
              <div class="col"><h5 class="mb-2">Type de Repas</h5></div>
              <div class="col d-flex justify-content-between mr-3">
                  <b-form-checkbox :disabled="!disabled || ODS_info.id > 0 || RepasService.length == 0" v-model="ODS_info.is_extra" name="check-button" switch>
                    Supplémentaire
                  </b-form-checkbox>
                  <b-form-checkbox :disabled="!disabled || ODS_info.id > 0 || RepasService.length == 0" v-model="estimated" name="check-button" switch>
                    Prévision
                  </b-form-checkbox>
              </div>
            </div>
            
            <div class="row mt-2" v-for="(rep,i) in RepasService" :key="i">
              <div class="col">
                <repas :visitors="visitors" :estimated="estimated" :disable="disabled" :key="rep" :pos="rep" :pob="pob" :service="filtredRepas" :send="send" @getvalues="Repas[`${rep}`] = $event" @getvaluesEmitted="getEmit = $event" />
              </div>
              
            </div>
          </div>

          <div class="row">
                  <div class="col text-right mr-2 mb-3" v-if="add">
                    <b-button :disabled="$v.ODS_info.$invalid || disableButtons || RepasService.length == 0 || ODS_info.status =='approved' || ODS_info.date_exec_ODS > selectedContract.end_date" @click="onComplete" variant="primary" class="btn-label mr-2">
                        <i class="far fa-save text-white label-icon"></i> Enregitrer
                    </b-button>

                    <b-button  @click="resetForm = true" variant="light" class="btn-label">
                        <i class="fas fa-times-circle text-danger label-icon"></i> Annuler 
                    </b-button>
                  </div>

                  <div class="col text-right mr-2 mb-3" v-if="edit || ODS_info.id ">
                    <b-button  @click="onUpdate" variant="primary" :disabled="!edit ||disableButtons || ODS_info.status =='approved' || ODS_info.date_exec_ODS > selectedContract.end_date" class="btn-label mr-2">
                        <i class="far fa-save text-white label-icon"></i> Enregitrer 
                    </b-button> 
                    
                    <b-button  @click="resetForm = true" variant="light" class="btn-label">
                        <i class="fas fa-times-circle text-danger label-icon"></i> Annuler 
                    </b-button>
                  </div>
                  
                </div>

      </div>
    </div>
    <!-- </Layout> -->
</template>
