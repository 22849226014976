<script>
import appConfig from "@/app.config";
// import { numeric,integer } from "vuelidate/lib/validators";
export default {
    props:['service','ODS','send','pos','estimated','disable','pob','visitors'],
    data() {
        return {
            active:false,
            keys:[],
            PersonOnBase:0,
            data:{
                // Ordinary_today:{meal_amount:0,unit_price:0},
                // Improved_today:{meal_amount:0,unit_price:0},
                // VIP_today:{meal_amount:0,unit_price:0},
                // endowment_today:{qte:0,amount:0,unit_price:0,}
                // comments:''
            }
        }
    },
    
    watch:{
        send:{
            handler(val){
                if(val) this.resetData();
            }
        },

        estimated:{
            handler(val){
                if(this.service[this.pos].length == 0) this.resetData();
                else {
                    for (let index = 0; index < this.service[this.pos].length; index++) {
                            this.getLastItem(this.service[this.pos][index].meal_type.designation,this.service[this.pos][index].meal_period.id,this.service[this.pos][index].id,this.service[this.pos][index].meal_service.id,this.service[this.pos][index].meal_type.id);
                            this.data[this.service[this.pos][index].meal_type.designation] = {
                                id :this.service[this.pos][index].id,
                                meal_type :this.service[this.pos][index].meal_type.id,
                                meal_service :this.service[this.pos][index].meal_service.id,
                                meal_period :this.service[this.pos][index].meal_period.id,
                                meal_contract :this.service[this.pos][index].estimated_qty,
                                contractual_qty :this.service[this.pos][index].contractual_qty,
                                default_qty :this.service[this.pos][index].default_qty
                            }

                            if(this.service[this.pos][index].prest) this.data[this.service[this.pos][index].meal_type.designation].prest =this.service[this.pos][index].prest;
                            if(this.service[this.pos][index].prestation) this.data[this.service[this.pos][index].meal_type.designation].prestation =this.service[this.pos][index].prestation;
                            if(this.service[this.pos][index].meal_amount) this.data[this.service[this.pos][index].meal_type.designation].meal_amount = this.service[this.pos][index].meal_amount;
                            if(this.estimated) this.data[this.service[this.pos][index].meal_type.designation].meal_amount = this.service[this.pos][index].estimated_qty;
                            else this.data[this.service[this.pos][index].meal_type.designation].meal_amount = 0;

                            if(this.service[this.pos][index].total_amount) this.data[this.service[this.pos][index].meal_type.designation].unit_price  = this.service[this.pos][index].total_amount / this.data.Ordinary_today.meal_amount ;
                            else this.data[this.service[this.pos][index].meal_type.designation].unit_price =this.service[this.pos][index].unit_price;
                        
                    }
                    this.emitData();
                }
                this.getTotal();
                this.getTotalCash();
                
            }
        },
        pos:{
            handler(val){
                // console.log(val);
            }
        },

    },
    mounted(){
        if(this.service[this.pos].length == 0) this.resetData();
        else{
            this.data={};
            for (let index = 0; index < this.service[this.pos].length; index++) {
                    this.getLastItem(this.service[this.pos][index].meal_type.designation,this.service[this.pos][index].meal_period.id,this.service[this.pos][index].id,this.service[this.pos][index].meal_service.id,this.service[this.pos][index].meal_type.id);
                    this.data[this.service[this.pos][index].meal_type.designation] = {
                        id :this.service[this.pos][index].id,
                        meal_type :this.service[this.pos][index].meal_type.id,
                        meal_service :this.service[this.pos][index].meal_service.id,
                        meal_period :this.service[this.pos][index].meal_period.id,
                        meal_contract :this.service[this.pos][index].estimated_qty,
                        contractual_qty :this.service[this.pos][index].contractual_qty,
                        default_qty :this.service[this.pos][index].default_qty
                    }
                    if(this.service[this.pos][index].prest) this.data[this.service[this.pos][index].meal_type.designation].prest =this.service[this.pos][index].prest;
                    if(this.service[this.pos][index].prestation) {
                        this.data[this.service[this.pos][index].meal_type.designation].prestation =this.service[this.pos][index].prestation;
                        this.data[this.service[this.pos][index].meal_type.designation].default_qty =this.service[this.pos][index].prestation.default_qty;
                    }
                    if(this.service[this.pos][index].meal_amount) this.data[this.service[this.pos][index].meal_type.designation].meal_amount = this.service[this.pos][index].meal_amount;
                    else{
                        if(this.estimated) this.data[this.service[this.pos][index].meal_type.designation].meal_amount = this.service[this.pos][index].estimated_qty;
                        else this.data[this.service[this.pos][index].meal_type.designation].meal_amount = 0;
                    }

                    if(this.service[this.pos][index].total_amount) {
                        this.data[this.service[this.pos][index].meal_type.designation].unit_price  = this.service[this.pos][index].prestation.unit_price;
                        this.data[this.service[this.pos][index].meal_type.designation].total_amount = this.service[this.pos][index].total_amount;
                    }
                    else this.data[this.service[this.pos][index].meal_type.designation].unit_price = this.service[this.pos][index].unit_price;
                
            }
            this.keys = Object.keys(this.data);
            this.getTotal();
            this.getTotalCash();
            this.emitData();
        }
        
        
    },
    methods:{
        getLastItem(repas,period,prest,service,meal){
            
            this.$http.post('ods/OdsRestauration/getlastitem',{period:period,prest:prest,service:service,meal:meal})
            .then(response => {
                if(response.data.length > 0) {
                    this.data[repas].lastQte  = response.data[0] ? parseInt(response.data[0].meal_verified_amount) : 0;
                    if(this.data[repas].contractual_qty )this.data[repas].leftQte  = response.data[1] ? parseInt(this.data[repas].contractual_qty) - parseInt(response.data[1]) : 0;
                    if(this.data[repas].prestation )this.data[repas].leftQte  = response.data[1] ? parseInt(this.data[repas].prestation.contractual_qty) - parseInt(response.data[1]) : 0;
                }
                
            })
            .catch(error => {
                console.log(error);
                this.data[repas].lastQte =0;
                this.data[repas].leftQte =0;
            })

            // return amount;
        },
        emitData(){
            this.$emit('getvalues',this.data);
            this.$emit('getvaluesEmitted',true);
            // console.log(this.data);
        },
        resetData(){
            this.data = {
                Ordinary_today:{meal_amount:0,unit_price:0},
                Improved_today:{meal_amount:0,unit_price:0},
                VIP_today:{meal_amount:0,unit_price:0},
            }
        },
        getTotal(){
            let total = 0;
            this.keys.forEach((element,i) => {
                if(this.service[this.pos][i].meal_amount != this.data[element]['meal_amount']){
                    // console.log(this.data[element]['meal_amount']);
                     total += parseInt(this.data[element]['meal_amount']);
                    if (total>0) this.emitData();
                    this.emitData();
                    this.getTotalCash()
                    return total;
                }
                if(this.service[this.pos][i].meal_amount){
                     total = 0;
                    this.service[this.pos].forEach(x => {
                        // console.log(x.meal_amount);
                        total+=parseInt(x.meal_amount);
                    });
                    if (total > 0) this.emitData();
                    this.getTotalCash()
                    return total;
                }
                // total = 0;
                // total += parseInt(this.data[element]['meal_amount']);
            })
            
            if (total>0) this.emitData();
            this.getTotalCash()
            return total;
        },
        getTotalCash(){
            let total_amount = 0
            this.keys.forEach(x => {
                if(this.service[this.pos][0].meal_amount != this.data[x]['meal_amount']){
                    this.data[x].total_amount = (parseInt(this.data[x]['meal_amount']) * parseInt(this.data[x]['unit_price'])) ;
                    // this.data.Improved_today.total_amount = (parseInt(this.data.Improved_today['meal_amount']) * parseInt(this.data.Improved_today['unit_price'])) ;
                    // this.data.VIP_today.total_amount = (parseInt(this.data.VIP_today['meal_amount']) * parseInt(this.data.VIP_today['unit_price'])) ;
                    total_amount += this.data[x].total_amount;
                    // if (total_amount > 0) this.emitData();
                    // this.emitData();
                    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(total_amount) ;
                }
                if(this.service[this.pos][0].total_amount){
                    total_amount = 0;
                    this.service[this.pos].forEach(element => {
                        total_amount+=parseInt(element.total_amount);
                    });
                    if (total_amount > 0) this.emitData();
                    return  new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(total_amount) ;
                }

                return total_amount += this.data[x].total_amount = (parseInt(this.data[x]['meal_amount']) * parseInt(this.data[x]['unit_price'])) ;
            })
                

            // console.log(this.service[this.pos][0].total_amount);
            // total_amount = this.data.Ordinary_today.total_amount + this.data.Improved_today.total_amount +this.data.VIP_today.total_amount ;
            if (total_amount > 0) this.emitData();
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(total_amount) ;
        },
        isNumber: function(evt) {
            // console.log(process.env.VUE_APP_HELP_URL);
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return;
            }
        }
    }
}
</script>
<template>
    <div>
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> {{service[`${pos}`][0].meal_service.designation}}</div>
                  <div class="col text-right">
                      <span>Total Repas: {{getTotal()}}  <span v-if="canAny(['confirm_rest_ods','validate_rest_ods'])">/ Coût: {{getTotalCash()}}</span> </span>
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse :id="`accordion-${pos}`" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
                  <fieldset :disabled="!disable">
                    <div class="mt-3" v-for="(repas,i) in keys" :key="i">
                        <h4 class="mb-2">{{repas}}</h4>
                            <div class="row">
                                <div class="col">
                                    <label for="">Quantité Précédente</label>
                                    <input type="text" class="form-control bg-light"  disabled v-model="data[repas]['lastQte']">
                                </div>
                                <div class="col">
                                    <label for="">Quantité Restante du contract</label>
                                    <input type="text" class="form-control bg-light"  disabled v-model="data[repas]['leftQte']">
                                </div>
                                <div class="col">
                                    <label for="">POB</label>
                                    <input type="text" class="form-control bg-light"  :value="pob+ (parseInt(pob)>=2 ? ' personnes':' personne') " readonly>
                                </div>
                                <div class="col">
                                    <label for="">Prise en Charges</label>
                                    <input type="text" class="form-control bg-light" :value="visitors+ (parseInt(visitors)>=2 ? ' personnes':' personne') "  readonly>
                                </div>
                                <div class="col">
                                    <label for="">Quantité Minimum</label>
                                    <input type="number" class="form-control" v-model="data[repas]['default_qty']" readonly>
                                </div>
                                <div class="col">
                                    <label for="">Quantité d'Aujourd'hui</label>
                                    <input type="number" class="form-control" v-model="data[repas]['meal_amount']" @keypress="isNumber($event)" @input="getTotal" placeholder="50">
                                </div>
                            </div>
                        
                    </div>
                    </fieldset>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
    </div>
</template>